.Mappage-container {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  background-color: var(--grey);
  align-items: center;
  height: auto;
  gap: 2rem;
}
.image-head-map1 {
  display: flex;
  flex-direction: row;
}
.image-head-map {
  width: 100%;
  height: 30%;
  z-index: 0;
}
.Title-map {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-72) / var(--unnamed-line-spacing-78)
    var(--unnamed-font-family-tajawal);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal bold 4vw regular;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  z-index: 10;
  position: absolute;
  left: 37%;
  margin-top: 4%;
}
.Main_job_listing {
  height: auto;
  background-color: var(--grey);
  padding-bottom: 5rem;
}

.joblisting_body {
  margin: auto;
  margin-top: 20rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Aboutus_heading_text_title_2 {
  font-size: 3rem;
  font-family: var(--bold);
  text-align: center;
  margin-top: 4rem;
}

.filter_bar {
  width: 80%;
  background-color: var(--grey);
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.jobs_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: auto;
  margin-top: 5rem;
}

.trash_cont {
  background-color: var(--grey);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.upperfilters_box {
  margin-top: 2rem;
}

.input_complete_search {
  width: 80%;
  padding: 20px;
  /* border: 1px solid var(--dark-grey); */
  border: none;
  border-radius: 8px;
}

.filter_icons_container {
  background-color: white;
  width: 100%;
}

.title_drop_cont {
  width: fit-content;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 20px;
}

.arrow_icon {
  transform: rotate(270deg);
}

.arrow_icon_rot {
  transform: rotate(90deg);
  transition: all 0.5s;
}

.icons_dropdown {
  position: relative;
  /* max-height: 300px;
    overflow-y: auto; */
}
.logo-modal {
  width: 3rem;
  height: 100%;
}

.title_drop {
  font-size: 1.1rem;
  font-family: var(--bold);
}

.Header_nav_dropdown_job {
  top: 60px;
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.5) !important;

  /* white-space: nowrap; */
  padding: 10px 10px;
  left: -80px;
  border-radius: 5px;
  height: 13rem;
  /* overflow-y: scroll; */

  overflow-y: scroll;
  z-index: 100;
  margin-left: 5rem;
  width: 17rem;
}
.lable-name {
  width: 100%;
}

.Header_nav_dropdown_job_2 {
  top: 60px;
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.5) !important;
  width: 14rem;

  padding: 10px 10px;
  left: -54px;
  border-radius: 5px;
  height: 5rem;
  overflow-y: scroll;
  z-index: 10;
}

.subsection_text_jobs {
  font-family: var(--bold);
  font-size: 1.1rem;
  color: var(--navy);
  padding: 5px 10px;
  cursor: pointer;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  gap: 5px;
}

.checkbox_filter {
  width: 15px;
  height: 15px;
}

.icons_drop {
  display: flex;
  /* margin: auto; */
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
}
.icons_drop1 {
  display: flex;
  /* margin: auto; */
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
}
.icons_drop12 {
  display: flex;
  /* margin: auto; */
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
}
.icons_drop12 {
  display: flex;
  /* margin: auto; */
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
}

.cont_search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  width: 100%;
  margin: auto;
  border-radius: 8px;
  background-color: white;
}
.modal-map {
  display: flex;
  flex-direction: column;
}
.Header-modal {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  font-family: var(--bold);
  color: var(--navy);
}
.modalmpa-item {
  color: var(--navy);
  font-family: var(--bold);
}
/* @media screen and (min-width: 440px) and (max-width: 590px) {
  .Header_nav_dropdown_job {
    right: 80px !important;
  }
} */
@media screen and (max-width: 628px) {
  .Header_nav_dropdown_job {
    margin-left: -4rem !important;
  }
}

@media screen and (max-width: 440px) {
  .icons_drop1 {
    display: flex;
    /* margin: auto; */
    flex-direction: row;
    justify-content: space-between;
    gap: 14rem;
    align-items: center;
    flex-wrap: wrap;
  }
  .icons_drop2 {
    display: flex;
    /* margin: auto; */
    flex-direction: row;
    justify-content: space-between;

    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 13rem;
  }
  .Header_nav_dropdown_job {
    margin-left: 5rem !important;
  }
}
