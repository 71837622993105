.Main_footer_div {
  /* background-color: #fff;
    background-repeat: no-repeat; */
  /* background: #D3D3D3 0% 0% no-repeat padding-box; */
  background-size: cover;
  /* box-shadow: 0 -5px 5px 0 rgba(0, 0, 0, .1) !important; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-top: 5rem; */
  padding: 30px 0px;
  width: 100%;
  height: auto;
}

.logo_header {
  width: 250px;
}

.footer_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin-bottom: 4rem;
}

.underline_foot {
  background-color: #171546;
  height: 1px;
  width: 100%;
}

.footer_footer_emailsub {
  align-items: center;
  /* background-color: rgba(240, 106, 128, .8); */
  background-color: #171546;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  margin: auto auto 1%;
  margin-top: 20px;
  padding: 30px 50px;
  width: 80%;
}

.footer_quick_links_title {
  font-family: var(--bold);
  font-size: 1.5rem;
  color: var(--navy);
  white-space: nowrap;
  cursor: pointer;
}

.a {
  text-decoration: none;
  color: #585858 !important;
}

.logo_cont_social_img {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
}

.logo_cont_social {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
  cursor: pointer;
}

.footer_emailsub_text {
  font-family: var(--bold);
  font-size: 1.5rem;
  color: white;
  white-space: nowrap;
  cursor: pointer;
}

.footer_emailsub_input {
  width: 100%;
}

.footer_input {
  width: 60%;
  height: 30px;
  border-radius: 12px;
  border: #585858 1px solid;
  padding: 5px;
  margin-top: 10px;
}

.button_footer {
  background-color: var(--purple);
  color: white;
  border: none;
  height: 40px;
  border-radius: 8px;
  padding: 5px;
  width: 100px;
  margin-left: 1rem;
  cursor: pointer;
}
.hadath {
  color: var(--navy);
}

.footer_container {
  display: flex;
  width: 80%;
  margin: auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 4rem;
  gap: 50px;
}

.underline_footer {
  background: #f8f9fb 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  height: 2px;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 5px;
}

.footer_container_items {
  font-size: 1rem;
  color: var(--navy);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: initial;
  cursor: pointer;
}

.footer_quick_links_item1 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.logo_cont_text {
  color: #585858 !important;
  width: 220px;
  text-align: initial;
  font-size: 1.1rem;
  margin-top: 1rem;
}

.logo_cont {
  display: flex;
  flex-direction: column;
  align-items: start;
}
