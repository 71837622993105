.tvet-cont{
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
}
.tvet-title{
 
justify-content: center;
display: flex;
    font: var(--bold);
    color: var(--navy);
    font-size: 2rem;
}

@media screen and (max-width:700px){
    .tvet-title{
        padding-left:20px;
    }
}
.video{
    display: flex;
justify-content: center;
margin-top: 3rem;
}
